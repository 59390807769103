.base-button {
  color: #fff;
  background: #cc3333 !important;
  border-radius: 10px;
  font-family: "Play";
  font-size: 24px;
  padding-left: 44px;
  padding-right: 44px;
}

.base-button:hover {
  color: #fff;
  transform: scale(1.01);
}

.dark-mode {
  color: #000;
}

.dark-mode:hover {
  color: #000;
}
