#sidebar {
  min-width: 280px;
}

.bolt-off {
  background: linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0.3) 100%);
}

.bolt-on {
  background: linear-gradient(
    180deg,
    #130b6c 0%,
    rgba(146, 27, 240, 0.33) 100%
  );
  transition: background-color 1000ms linear;
}

/* Style for profile section */
#profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 65px;
}

#profile > img {
  width: 100px;
  border-radius: 10px;
  margin-bottom: 20px;
}

#profile > #username {
  font-weight: bold;
  font-size: 36px;
  margin: 0;
}

#profile > #stats {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#stats > #stat-streak {
  margin-right: 10px;
}

#stats > #stat-party {
  margin-left: 10px;
}

#stats .stat-logo {
  margin-right: 10px;
}

/* Handle Red Active Bar */
#sidebar > #active-bar {
  position: absolute;
  transition: 0.2s;
}

#sidebar > .active-bar-0 {
  top: 405px;
}

#sidebar > .active-bar-1 {
  top: 462px;
}

#sidebar > .active-bar-2 {
  top: 519px;
}

#sidebar > .active-bar-3 {
  top: 576px;
}

/* Style for tabs section */
#tabs {
  list-style-type: none;
  margin-bottom: 65px;
  padding: 0;
  margin-left: 20%;
}

#tabs li {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
}

#tabs img {
  width: 25px;
}

#tabs p {
  margin: 0;
  margin-left: 10px;
  font-size: 28px;
}

.tab-active {
  color: #cc3333;
}

/* Style for THE BUTTON section */
#the-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#the-button-container > button {
  margin-bottom: 20px;
  border: solid #cc3333 2px;
  border-radius: 15px;
  padding: 0;
  height: 65px;
  width: 65px;
}

#the-button-container > button:focus {
  box-shadow: none;
}

#the-button-container > button:active {
  transform: scale(1.03);
}

#the-button-container > button > img {
  width: 60%;
}

#the-button-container > p {
  max-width: 200px;
  margin-bottom: 20px;
}

/* Circle Attempt */
/* Taken from: https://codepen.io/tonkotsuboy/pen/zJbKNN */
/* Once we can convert to SCSS, we don't need all these 4000 lines of
 * CSS. Instead we can just use the following SCSS code:
 *  

.circle-container {
  $particleNum: 200;
  $particleColor: hsl(180, 100%, 80%);

  position: absolute;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(
      hsl(180, 100%, 80%),
      hsl(180, 100%, 80%) 10%,
      hsla(180, 100%, 80%, 0) 56%
    );

    animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

    @keyframes fade-frames {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes scale-frames {
      0% {
        transform: scale3d(0.4, 0.4, 1);
      }

      50% {
        transform: scale3d(2.2, 2.2, 1);
      }

      100% {
        transform: scale3d(0.4, 0.4, 1);
      }
    }
  }

  $particleBaseSize: 8;

  
  @for $i from 1 through $particleNum {
    &:nth-child(#{$i}) {
      $circleSize: random($particleBaseSize);
      width: $circleSize + px;
      height: $circleSize + px;

      $startPositionY: random(10) + 80;
      $framesName: "move-frames-" + $i;
      $moveDuration: 28000 + random(9000) + ms;

      animation-name: #{$framesName};
      animation-duration: $moveDuration;
      animation-delay: random(37000) + ms;

      @keyframes #{$framesName} {
        from {
          transform: translate3d(
            #{random(280) + px},
            #{$startPositionY + vh},
            0
          );
        }

        to {
          transform: translate3d(
            #{random(280) + px},
            #{- $startPositionY - random(30) + vh},
            0
          );
        }
      }

      .circle {
        animation-delay: random(4000) + ms;
      }
    }
  }
}
 */
.circle-container {
  position: absolute;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.circle-container .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(
    #99ffff,
    #99ffff 10%,
    rgba(153, 255, 255, 0) 56%
  );
  animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}

@keyframes fade-frames {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }

  50% {
    transform: scale3d(2.2, 2.2, 1);
  }

  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}

.circle-container:nth-child(1) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-1;
  animation-duration: 35630ms;
  animation-delay: 12724ms;
}

@keyframes move-frames-1 {
  from {
    transform: translate3d(259px, 88vh, 0);
  }

  to {
    transform: translate3d(59px, -106vh, 0);
  }
}

.circle-container:nth-child(1) .circle {
  animation-delay: 215ms;
}

.circle-container:nth-child(2) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-2;
  animation-duration: 36915ms;
  animation-delay: 27082ms;
}

@keyframes move-frames-2 {
  from {
    transform: translate3d(264px, 82vh, 0);
  }

  to {
    transform: translate3d(58px, -112vh, 0);
  }
}

.circle-container:nth-child(2) .circle {
  animation-delay: 86ms;
}

.circle-container:nth-child(3) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-3;
  animation-duration: 34895ms;
  animation-delay: 580ms;
}

@keyframes move-frames-3 {
  from {
    transform: translate3d(151px, 89vh, 0);
  }

  to {
    transform: translate3d(189px, -106vh, 0);
  }
}

.circle-container:nth-child(3) .circle {
  animation-delay: 3797ms;
}

.circle-container:nth-child(4) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-4;
  animation-duration: 28509ms;
  animation-delay: 4941ms;
}

@keyframes move-frames-4 {
  from {
    transform: translate3d(106px, 87vh, 0);
  }

  to {
    transform: translate3d(6px, -90vh, 0);
  }
}

.circle-container:nth-child(4) .circle {
  animation-delay: 698ms;
}

.circle-container:nth-child(5) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-5;
  animation-duration: 32339ms;
  animation-delay: 15092ms;
}

@keyframes move-frames-5 {
  from {
    transform: translate3d(37px, 89vh, 0);
  }

  to {
    transform: translate3d(30px, -104vh, 0);
  }
}

.circle-container:nth-child(5) .circle {
  animation-delay: 579ms;
}

.circle-container:nth-child(6) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-6;
  animation-duration: 29601ms;
  animation-delay: 40ms;
}

@keyframes move-frames-6 {
  from {
    transform: translate3d(189px, 81vh, 0);
  }

  to {
    transform: translate3d(77px, -88vh, 0);
  }
}

.circle-container:nth-child(6) .circle {
  animation-delay: 1339ms;
}

.circle-container:nth-child(7) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-7;
  animation-duration: 32829ms;
  animation-delay: 34492ms;
}

@keyframes move-frames-7 {
  from {
    transform: translate3d(239px, 85vh, 0);
  }

  to {
    transform: translate3d(38px, -115vh, 0);
  }
}

.circle-container:nth-child(7) .circle {
  animation-delay: 906ms;
}

.circle-container:nth-child(8) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-8;
  animation-duration: 30125ms;
  animation-delay: 17525ms;
}

@keyframes move-frames-8 {
  from {
    transform: translate3d(5px, 81vh, 0);
  }

  to {
    transform: translate3d(144px, -104vh, 0);
  }
}

.circle-container:nth-child(8) .circle {
  animation-delay: 1931ms;
}

.circle-container:nth-child(9) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-9;
  animation-duration: 35191ms;
  animation-delay: 29416ms;
}

@keyframes move-frames-9 {
  from {
    transform: translate3d(65px, 83vh, 0);
  }

  to {
    transform: translate3d(161px, -107vh, 0);
  }
}

.circle-container:nth-child(9) .circle {
  animation-delay: 3479ms;
}

.circle-container:nth-child(10) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-10;
  animation-duration: 34366ms;
  animation-delay: 7562ms;
}

@keyframes move-frames-10 {
  from {
    transform: translate3d(9px, 89vh, 0);
  }

  to {
    transform: translate3d(48px, -117vh, 0);
  }
}

.circle-container:nth-child(10) .circle {
  animation-delay: 1748ms;
}

.circle-container:nth-child(11) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-11;
  animation-duration: 33693ms;
  animation-delay: 22661ms;
}

@keyframes move-frames-11 {
  from {
    transform: translate3d(118px, 87vh, 0);
  }

  to {
    transform: translate3d(225px, -98vh, 0);
  }
}

.circle-container:nth-child(11) .circle {
  animation-delay: 417ms;
}

.circle-container:nth-child(12) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-12;
  animation-duration: 29069ms;
  animation-delay: 27323ms;
}

@keyframes move-frames-12 {
  from {
    transform: translate3d(98px, 84vh, 0);
  }

  to {
    transform: translate3d(16px, -90vh, 0);
  }
}

.circle-container:nth-child(12) .circle {
  animation-delay: 2197ms;
}

.circle-container:nth-child(13) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-13;
  animation-duration: 36025ms;
  animation-delay: 33096ms;
}

@keyframes move-frames-13 {
  from {
    transform: translate3d(146px, 86vh, 0);
  }

  to {
    transform: translate3d(214px, -94vh, 0);
  }
}

.circle-container:nth-child(13) .circle {
  animation-delay: 3475ms;
}

.circle-container:nth-child(14) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-14;
  animation-duration: 32865ms;
  animation-delay: 498ms;
}

@keyframes move-frames-14 {
  from {
    transform: translate3d(227px, 86vh, 0);
  }

  to {
    transform: translate3d(273px, -114vh, 0);
  }
}

.circle-container:nth-child(14) .circle {
  animation-delay: 2781ms;
}

.circle-container:nth-child(15) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-15;
  animation-duration: 32015ms;
  animation-delay: 29756ms;
}

@keyframes move-frames-15 {
  from {
    transform: translate3d(167px, 81vh, 0);
  }

  to {
    transform: translate3d(67px, -103vh, 0);
  }
}

.circle-container:nth-child(15) .circle {
  animation-delay: 1853ms;
}

.circle-container:nth-child(16) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-16;
  animation-duration: 30771ms;
  animation-delay: 10508ms;
}

@keyframes move-frames-16 {
  from {
    transform: translate3d(227px, 83vh, 0);
  }

  to {
    transform: translate3d(76px, -102vh, 0);
  }
}

.circle-container:nth-child(16) .circle {
  animation-delay: 1050ms;
}

.circle-container:nth-child(17) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-17;
  animation-duration: 35838ms;
  animation-delay: 18449ms;
}

@keyframes move-frames-17 {
  from {
    transform: translate3d(62px, 82vh, 0);
  }

  to {
    transform: translate3d(173px, -98vh, 0);
  }
}

.circle-container:nth-child(17) .circle {
  animation-delay: 3693ms;
}

.circle-container:nth-child(18) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-18;
  animation-duration: 31521ms;
  animation-delay: 18886ms;
}

@keyframes move-frames-18 {
  from {
    transform: translate3d(95px, 87vh, 0);
  }

  to {
    transform: translate3d(106px, -98vh, 0);
  }
}

.circle-container:nth-child(18) .circle {
  animation-delay: 1856ms;
}

.circle-container:nth-child(19) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-19;
  animation-duration: 34462ms;
  animation-delay: 11488ms;
}

@keyframes move-frames-19 {
  from {
    transform: translate3d(78px, 86vh, 0);
  }

  to {
    transform: translate3d(141px, -89vh, 0);
  }
}

.circle-container:nth-child(19) .circle {
  animation-delay: 3851ms;
}

.circle-container:nth-child(20) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-20;
  animation-duration: 31216ms;
  animation-delay: 31385ms;
}

@keyframes move-frames-20 {
  from {
    transform: translate3d(151px, 86vh, 0);
  }

  to {
    transform: translate3d(76px, -95vh, 0);
  }
}

.circle-container:nth-child(20) .circle {
  animation-delay: 2025ms;
}

.circle-container:nth-child(21) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-21;
  animation-duration: 36657ms;
  animation-delay: 6442ms;
}

@keyframes move-frames-21 {
  from {
    transform: translate3d(226px, 85vh, 0);
  }

  to {
    transform: translate3d(16px, -86vh, 0);
  }
}

.circle-container:nth-child(21) .circle {
  animation-delay: 480ms;
}

.circle-container:nth-child(22) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-22;
  animation-duration: 33330ms;
  animation-delay: 5325ms;
}

@keyframes move-frames-22 {
  from {
    transform: translate3d(95px, 85vh, 0);
  }

  to {
    transform: translate3d(239px, -107vh, 0);
  }
}

.circle-container:nth-child(22) .circle {
  animation-delay: 3169ms;
}

.circle-container:nth-child(23) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-23;
  animation-duration: 33539ms;
  animation-delay: 8410ms;
}

@keyframes move-frames-23 {
  from {
    transform: translate3d(163px, 89vh, 0);
  }

  to {
    transform: translate3d(243px, -98vh, 0);
  }
}

.circle-container:nth-child(23) .circle {
  animation-delay: 3913ms;
}

.circle-container:nth-child(24) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-24;
  animation-duration: 29687ms;
  animation-delay: 34642ms;
}

@keyframes move-frames-24 {
  from {
    transform: translate3d(269px, 87vh, 0);
  }

  to {
    transform: translate3d(66px, -94vh, 0);
  }
}

.circle-container:nth-child(24) .circle {
  animation-delay: 3439ms;
}

.circle-container:nth-child(25) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-25;
  animation-duration: 35693ms;
  animation-delay: 9290ms;
}

@keyframes move-frames-25 {
  from {
    transform: translate3d(78px, 88vh, 0);
  }

  to {
    transform: translate3d(133px, -113vh, 0);
  }
}

.circle-container:nth-child(25) .circle {
  animation-delay: 1197ms;
}

.circle-container:nth-child(26) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-26;
  animation-duration: 33593ms;
  animation-delay: 27082ms;
}

@keyframes move-frames-26 {
  from {
    transform: translate3d(257px, 84vh, 0);
  }

  to {
    transform: translate3d(52px, -109vh, 0);
  }
}

.circle-container:nth-child(26) .circle {
  animation-delay: 3199ms;
}

.circle-container:nth-child(27) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-27;
  animation-duration: 33215ms;
  animation-delay: 21561ms;
}

@keyframes move-frames-27 {
  from {
    transform: translate3d(269px, 87vh, 0);
  }

  to {
    transform: translate3d(200px, -105vh, 0);
  }
}

.circle-container:nth-child(27) .circle {
  animation-delay: 3247ms;
}

.circle-container:nth-child(28) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-28;
  animation-duration: 29277ms;
  animation-delay: 29346ms;
}

@keyframes move-frames-28 {
  from {
    transform: translate3d(268px, 85vh, 0);
  }

  to {
    transform: translate3d(111px, -86vh, 0);
  }
}

.circle-container:nth-child(28) .circle {
  animation-delay: 3196ms;
}

.circle-container:nth-child(29) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-29;
  animation-duration: 30533ms;
  animation-delay: 4380ms;
}

@keyframes move-frames-29 {
  from {
    transform: translate3d(233px, 87vh, 0);
  }

  to {
    transform: translate3d(14px, -89vh, 0);
  }
}

.circle-container:nth-child(29) .circle {
  animation-delay: 3359ms;
}

.circle-container:nth-child(30) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-30;
  animation-duration: 28616ms;
  animation-delay: 15242ms;
}

@keyframes move-frames-30 {
  from {
    transform: translate3d(276px, 87vh, 0);
  }

  to {
    transform: translate3d(39px, -103vh, 0);
  }
}

.circle-container:nth-child(30) .circle {
  animation-delay: 91ms;
}

.circle-container:nth-child(31) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-31;
  animation-duration: 33039ms;
  animation-delay: 32900ms;
}

@keyframes move-frames-31 {
  from {
    transform: translate3d(113px, 81vh, 0);
  }

  to {
    transform: translate3d(151px, -99vh, 0);
  }
}

.circle-container:nth-child(31) .circle {
  animation-delay: 733ms;
}

.circle-container:nth-child(32) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-32;
  animation-duration: 32356ms;
  animation-delay: 4605ms;
}

@keyframes move-frames-32 {
  from {
    transform: translate3d(46px, 87vh, 0);
  }

  to {
    transform: translate3d(197px, -109vh, 0);
  }
}

.circle-container:nth-child(32) .circle {
  animation-delay: 558ms;
}

.circle-container:nth-child(33) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-33;
  animation-duration: 34282ms;
  animation-delay: 15164ms;
}

@keyframes move-frames-33 {
  from {
    transform: translate3d(239px, 81vh, 0);
  }

  to {
    transform: translate3d(202px, -91vh, 0);
  }
}

.circle-container:nth-child(33) .circle {
  animation-delay: 3663ms;
}

.circle-container:nth-child(34) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-34;
  animation-duration: 34705ms;
  animation-delay: 21734ms;
}

@keyframes move-frames-34 {
  from {
    transform: translate3d(61px, 86vh, 0);
  }

  to {
    transform: translate3d(127px, -115vh, 0);
  }
}

.circle-container:nth-child(34) .circle {
  animation-delay: 3140ms;
}

.circle-container:nth-child(35) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-35;
  animation-duration: 31260ms;
  animation-delay: 9632ms;
}

@keyframes move-frames-35 {
  from {
    transform: translate3d(39px, 90vh, 0);
  }

  to {
    transform: translate3d(47px, -102vh, 0);
  }
}

.circle-container:nth-child(35) .circle {
  animation-delay: 59ms;
}

.circle-container:nth-child(36) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-36;
  animation-duration: 33685ms;
  animation-delay: 862ms;
}

@keyframes move-frames-36 {
  from {
    transform: translate3d(133px, 81vh, 0);
  }

  to {
    transform: translate3d(31px, -103vh, 0);
  }
}

.circle-container:nth-child(36) .circle {
  animation-delay: 3997ms;
}

.circle-container:nth-child(37) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-37;
  animation-duration: 28071ms;
  animation-delay: 36249ms;
}

@keyframes move-frames-37 {
  from {
    transform: translate3d(51px, 87vh, 0);
  }

  to {
    transform: translate3d(100px, -98vh, 0);
  }
}

.circle-container:nth-child(37) .circle {
  animation-delay: 1409ms;
}

.circle-container:nth-child(38) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-38;
  animation-duration: 36786ms;
  animation-delay: 4436ms;
}

@keyframes move-frames-38 {
  from {
    transform: translate3d(233px, 83vh, 0);
  }

  to {
    transform: translate3d(20px, -109vh, 0);
  }
}

.circle-container:nth-child(38) .circle {
  animation-delay: 1194ms;
}

.circle-container:nth-child(39) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-39;
  animation-duration: 29752ms;
  animation-delay: 36759ms;
}

@keyframes move-frames-39 {
  from {
    transform: translate3d(115px, 89vh, 0);
  }

  to {
    transform: translate3d(38px, -103vh, 0);
  }
}

.circle-container:nth-child(39) .circle {
  animation-delay: 731ms;
}

.circle-container:nth-child(40) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-40;
  animation-duration: 33002ms;
  animation-delay: 2246ms;
}

@keyframes move-frames-40 {
  from {
    transform: translate3d(154px, 86vh, 0);
  }

  to {
    transform: translate3d(94px, -116vh, 0);
  }
}

.circle-container:nth-child(40) .circle {
  animation-delay: 978ms;
}

.circle-container:nth-child(41) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-41;
  animation-duration: 28468ms;
  animation-delay: 20751ms;
}

@keyframes move-frames-41 {
  from {
    transform: translate3d(94px, 87vh, 0);
  }

  to {
    transform: translate3d(275px, -104vh, 0);
  }
}

.circle-container:nth-child(41) .circle {
  animation-delay: 3477ms;
}

.circle-container:nth-child(42) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-42;
  animation-duration: 33002ms;
  animation-delay: 11162ms;
}

@keyframes move-frames-42 {
  from {
    transform: translate3d(40px, 85vh, 0);
  }

  to {
    transform: translate3d(92px, -95vh, 0);
  }
}

.circle-container:nth-child(42) .circle {
  animation-delay: 155ms;
}

.circle-container:nth-child(43) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-43;
  animation-duration: 30833ms;
  animation-delay: 36595ms;
}

@keyframes move-frames-43 {
  from {
    transform: translate3d(122px, 89vh, 0);
  }

  to {
    transform: translate3d(25px, -101vh, 0);
  }
}

.circle-container:nth-child(43) .circle {
  animation-delay: 1462ms;
}

.circle-container:nth-child(44) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-44;
  animation-duration: 29633ms;
  animation-delay: 666ms;
}

@keyframes move-frames-44 {
  from {
    transform: translate3d(186px, 82vh, 0);
  }

  to {
    transform: translate3d(116px, -94vh, 0);
  }
}

.circle-container:nth-child(44) .circle {
  animation-delay: 385ms;
}

.circle-container:nth-child(45) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-45;
  animation-duration: 32868ms;
  animation-delay: 14972ms;
}

@keyframes move-frames-45 {
  from {
    transform: translate3d(142px, 83vh, 0);
  }

  to {
    transform: translate3d(30px, -103vh, 0);
  }
}

.circle-container:nth-child(45) .circle {
  animation-delay: 2204ms;
}

.circle-container:nth-child(46) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-46;
  animation-duration: 29361ms;
  animation-delay: 34178ms;
}

@keyframes move-frames-46 {
  from {
    transform: translate3d(158px, 84vh, 0);
  }

  to {
    transform: translate3d(173px, -91vh, 0);
  }
}

.circle-container:nth-child(46) .circle {
  animation-delay: 3922ms;
}

.circle-container:nth-child(47) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-47;
  animation-duration: 35749ms;
  animation-delay: 26076ms;
}

@keyframes move-frames-47 {
  from {
    transform: translate3d(164px, 86vh, 0);
  }

  to {
    transform: translate3d(8px, -90vh, 0);
  }
}

.circle-container:nth-child(47) .circle {
  animation-delay: 2217ms;
}

.circle-container:nth-child(48) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-48;
  animation-duration: 30825ms;
  animation-delay: 30903ms;
}

@keyframes move-frames-48 {
  from {
    transform: translate3d(118px, 84vh, 0);
  }

  to {
    transform: translate3d(124px, -100vh, 0);
  }
}

.circle-container:nth-child(48) .circle {
  animation-delay: 3314ms;
}

.circle-container:nth-child(49) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-49;
  animation-duration: 31751ms;
  animation-delay: 17301ms;
}

@keyframes move-frames-49 {
  from {
    transform: translate3d(33px, 87vh, 0);
  }

  to {
    transform: translate3d(255px, -90vh, 0);
  }
}

.circle-container:nth-child(49) .circle {
  animation-delay: 571ms;
}

.circle-container:nth-child(50) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-50;
  animation-duration: 30120ms;
  animation-delay: 19542ms;
}

@keyframes move-frames-50 {
  from {
    transform: translate3d(265px, 85vh, 0);
  }

  to {
    transform: translate3d(69px, -99vh, 0);
  }
}

.circle-container:nth-child(50) .circle {
  animation-delay: 2016ms;
}

.circle-container:nth-child(51) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-51;
  animation-duration: 29030ms;
  animation-delay: 35194ms;
}

@keyframes move-frames-51 {
  from {
    transform: translate3d(213px, 89vh, 0);
  }

  to {
    transform: translate3d(204px, -99vh, 0);
  }
}

.circle-container:nth-child(51) .circle {
  animation-delay: 1331ms;
}

.circle-container:nth-child(52) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-52;
  animation-duration: 36836ms;
  animation-delay: 5097ms;
}

@keyframes move-frames-52 {
  from {
    transform: translate3d(32px, 81vh, 0);
  }

  to {
    transform: translate3d(250px, -90vh, 0);
  }
}

.circle-container:nth-child(52) .circle {
  animation-delay: 2112ms;
}

.circle-container:nth-child(53) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-53;
  animation-duration: 31392ms;
  animation-delay: 30642ms;
}

@keyframes move-frames-53 {
  from {
    transform: translate3d(92px, 84vh, 0);
  }

  to {
    transform: translate3d(198px, -101vh, 0);
  }
}

.circle-container:nth-child(53) .circle {
  animation-delay: 407ms;
}

.circle-container:nth-child(54) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-54;
  animation-duration: 34252ms;
  animation-delay: 27177ms;
}

@keyframes move-frames-54 {
  from {
    transform: translate3d(68px, 87vh, 0);
  }

  to {
    transform: translate3d(103px, -105vh, 0);
  }
}

.circle-container:nth-child(54) .circle {
  animation-delay: 2591ms;
}

.circle-container:nth-child(55) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-55;
  animation-duration: 29945ms;
  animation-delay: 7066ms;
}

@keyframes move-frames-55 {
  from {
    transform: translate3d(119px, 87vh, 0);
  }

  to {
    transform: translate3d(83px, -105vh, 0);
  }
}

.circle-container:nth-child(55) .circle {
  animation-delay: 3393ms;
}

.circle-container:nth-child(56) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-56;
  animation-duration: 30226ms;
  animation-delay: 903ms;
}

@keyframes move-frames-56 {
  from {
    transform: translate3d(262px, 88vh, 0);
  }

  to {
    transform: translate3d(242px, -93vh, 0);
  }
}

.circle-container:nth-child(56) .circle {
  animation-delay: 979ms;
}

.circle-container:nth-child(57) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-57;
  animation-duration: 31772ms;
  animation-delay: 3101ms;
}

@keyframes move-frames-57 {
  from {
    transform: translate3d(245px, 90vh, 0);
  }

  to {
    transform: translate3d(54px, -112vh, 0);
  }
}

.circle-container:nth-child(57) .circle {
  animation-delay: 267ms;
}

.circle-container:nth-child(58) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-58;
  animation-duration: 36358ms;
  animation-delay: 15339ms;
}

@keyframes move-frames-58 {
  from {
    transform: translate3d(181px, 81vh, 0);
  }

  to {
    transform: translate3d(40px, -111vh, 0);
  }
}

.circle-container:nth-child(58) .circle {
  animation-delay: 1482ms;
}

.circle-container:nth-child(59) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-59;
  animation-duration: 35843ms;
  animation-delay: 36767ms;
}

@keyframes move-frames-59 {
  from {
    transform: translate3d(109px, 90vh, 0);
  }

  to {
    transform: translate3d(31px, -111vh, 0);
  }
}

.circle-container:nth-child(59) .circle {
  animation-delay: 2457ms;
}

.circle-container:nth-child(60) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-60;
  animation-duration: 29443ms;
  animation-delay: 28598ms;
}

@keyframes move-frames-60 {
  from {
    transform: translate3d(240px, 81vh, 0);
  }

  to {
    transform: translate3d(99px, -101vh, 0);
  }
}

.circle-container:nth-child(60) .circle {
  animation-delay: 2331ms;
}

.circle-container:nth-child(61) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-61;
  animation-duration: 35352ms;
  animation-delay: 33623ms;
}

@keyframes move-frames-61 {
  from {
    transform: translate3d(253px, 84vh, 0);
  }

  to {
    transform: translate3d(95px, -89vh, 0);
  }
}

.circle-container:nth-child(61) .circle {
  animation-delay: 1720ms;
}

.circle-container:nth-child(62) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-62;
  animation-duration: 35869ms;
  animation-delay: 5776ms;
}

@keyframes move-frames-62 {
  from {
    transform: translate3d(132px, 88vh, 0);
  }

  to {
    transform: translate3d(102px, -105vh, 0);
  }
}

.circle-container:nth-child(62) .circle {
  animation-delay: 1781ms;
}

.circle-container:nth-child(63) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-63;
  animation-duration: 32504ms;
  animation-delay: 2627ms;
}

@keyframes move-frames-63 {
  from {
    transform: translate3d(51px, 84vh, 0);
  }

  to {
    transform: translate3d(273px, -106vh, 0);
  }
}

.circle-container:nth-child(63) .circle {
  animation-delay: 62ms;
}

.circle-container:nth-child(64) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-64;
  animation-duration: 31235ms;
  animation-delay: 5099ms;
}

@keyframes move-frames-64 {
  from {
    transform: translate3d(116px, 86vh, 0);
  }

  to {
    transform: translate3d(167px, -106vh, 0);
  }
}

.circle-container:nth-child(64) .circle {
  animation-delay: 1073ms;
}

.circle-container:nth-child(65) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-65;
  animation-duration: 32493ms;
  animation-delay: 1466ms;
}

@keyframes move-frames-65 {
  from {
    transform: translate3d(141px, 81vh, 0);
  }

  to {
    transform: translate3d(254px, -91vh, 0);
  }
}

.circle-container:nth-child(65) .circle {
  animation-delay: 1481ms;
}

.circle-container:nth-child(66) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-66;
  animation-duration: 32048ms;
  animation-delay: 27408ms;
}

@keyframes move-frames-66 {
  from {
    transform: translate3d(257px, 82vh, 0);
  }

  to {
    transform: translate3d(78px, -88vh, 0);
  }
}

.circle-container:nth-child(66) .circle {
  animation-delay: 1572ms;
}

.circle-container:nth-child(67) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-67;
  animation-duration: 30322ms;
  animation-delay: 12118ms;
}

@keyframes move-frames-67 {
  from {
    transform: translate3d(99px, 88vh, 0);
  }

  to {
    transform: translate3d(108px, -94vh, 0);
  }
}

.circle-container:nth-child(67) .circle {
  animation-delay: 3581ms;
}

.circle-container:nth-child(68) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-68;
  animation-duration: 29878ms;
  animation-delay: 23833ms;
}

@keyframes move-frames-68 {
  from {
    transform: translate3d(255px, 83vh, 0);
  }

  to {
    transform: translate3d(209px, -113vh, 0);
  }
}

.circle-container:nth-child(68) .circle {
  animation-delay: 1246ms;
}

.circle-container:nth-child(69) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-69;
  animation-duration: 34866ms;
  animation-delay: 30559ms;
}

@keyframes move-frames-69 {
  from {
    transform: translate3d(102px, 84vh, 0);
  }

  to {
    transform: translate3d(89px, -91vh, 0);
  }
}

.circle-container:nth-child(69) .circle {
  animation-delay: 3907ms;
}

.circle-container:nth-child(70) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-70;
  animation-duration: 31487ms;
  animation-delay: 2537ms;
}

@keyframes move-frames-70 {
  from {
    transform: translate3d(180px, 88vh, 0);
  }

  to {
    transform: translate3d(273px, -114vh, 0);
  }
}

.circle-container:nth-child(70) .circle {
  animation-delay: 905ms;
}

.circle-container:nth-child(71) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-71;
  animation-duration: 28663ms;
  animation-delay: 21667ms;
}

@keyframes move-frames-71 {
  from {
    transform: translate3d(59px, 83vh, 0);
  }

  to {
    transform: translate3d(112px, -102vh, 0);
  }
}

.circle-container:nth-child(71) .circle {
  animation-delay: 136ms;
}

.circle-container:nth-child(72) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-72;
  animation-duration: 29422ms;
  animation-delay: 35851ms;
}

@keyframes move-frames-72 {
  from {
    transform: translate3d(121px, 85vh, 0);
  }

  to {
    transform: translate3d(60px, -98vh, 0);
  }
}

.circle-container:nth-child(72) .circle {
  animation-delay: 352ms;
}

.circle-container:nth-child(73) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-73;
  animation-duration: 32853ms;
  animation-delay: 4450ms;
}

@keyframes move-frames-73 {
  from {
    transform: translate3d(46px, 89vh, 0);
  }

  to {
    transform: translate3d(88px, -112vh, 0);
  }
}

.circle-container:nth-child(73) .circle {
  animation-delay: 145ms;
}

.circle-container:nth-child(74) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-74;
  animation-duration: 32442ms;
  animation-delay: 25628ms;
}

@keyframes move-frames-74 {
  from {
    transform: translate3d(254px, 84vh, 0);
  }

  to {
    transform: translate3d(249px, -99vh, 0);
  }
}

.circle-container:nth-child(74) .circle {
  animation-delay: 3425ms;
}

.circle-container:nth-child(75) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-75;
  animation-duration: 36407ms;
  animation-delay: 19319ms;
}

@keyframes move-frames-75 {
  from {
    transform: translate3d(238px, 90vh, 0);
  }

  to {
    transform: translate3d(264px, -99vh, 0);
  }
}

.circle-container:nth-child(75) .circle {
  animation-delay: 2434ms;
}

.circle-container:nth-child(76) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-76;
  animation-duration: 31274ms;
  animation-delay: 12795ms;
}

@keyframes move-frames-76 {
  from {
    transform: translate3d(74px, 85vh, 0);
  }

  to {
    transform: translate3d(132px, -89vh, 0);
  }
}

.circle-container:nth-child(76) .circle {
  animation-delay: 3351ms;
}

.circle-container:nth-child(77) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-77;
  animation-duration: 31542ms;
  animation-delay: 22414ms;
}

@keyframes move-frames-77 {
  from {
    transform: translate3d(197px, 86vh, 0);
  }

  to {
    transform: translate3d(33px, -108vh, 0);
  }
}

.circle-container:nth-child(77) .circle {
  animation-delay: 3652ms;
}

.circle-container:nth-child(78) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-78;
  animation-duration: 32639ms;
  animation-delay: 33885ms;
}

@keyframes move-frames-78 {
  from {
    transform: translate3d(239px, 86vh, 0);
  }

  to {
    transform: translate3d(128px, -87vh, 0);
  }
}

.circle-container:nth-child(78) .circle {
  animation-delay: 2704ms;
}

.circle-container:nth-child(79) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-79;
  animation-duration: 33140ms;
  animation-delay: 28470ms;
}

@keyframes move-frames-79 {
  from {
    transform: translate3d(258px, 88vh, 0);
  }

  to {
    transform: translate3d(275px, -106vh, 0);
  }
}

.circle-container:nth-child(79) .circle {
  animation-delay: 1867ms;
}

.circle-container:nth-child(80) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-80;
  animation-duration: 31921ms;
  animation-delay: 20574ms;
}

@keyframes move-frames-80 {
  from {
    transform: translate3d(221px, 89vh, 0);
  }

  to {
    transform: translate3d(227px, -99vh, 0);
  }
}

.circle-container:nth-child(80) .circle {
  animation-delay: 2847ms;
}

.circle-container:nth-child(81) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-81;
  animation-duration: 35221ms;
  animation-delay: 35769ms;
}

@keyframes move-frames-81 {
  from {
    transform: translate3d(81px, 89vh, 0);
  }

  to {
    transform: translate3d(9px, -106vh, 0);
  }
}

.circle-container:nth-child(81) .circle {
  animation-delay: 2751ms;
}

.circle-container:nth-child(82) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-82;
  animation-duration: 30536ms;
  animation-delay: 790ms;
}

@keyframes move-frames-82 {
  from {
    transform: translate3d(68px, 86vh, 0);
  }

  to {
    transform: translate3d(155px, -100vh, 0);
  }
}

.circle-container:nth-child(82) .circle {
  animation-delay: 2577ms;
}

.circle-container:nth-child(83) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-83;
  animation-duration: 34567ms;
  animation-delay: 22126ms;
}

@keyframes move-frames-83 {
  from {
    transform: translate3d(85px, 85vh, 0);
  }

  to {
    transform: translate3d(203px, -106vh, 0);
  }
}

.circle-container:nth-child(83) .circle {
  animation-delay: 2871ms;
}

.circle-container:nth-child(84) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-84;
  animation-duration: 31945ms;
  animation-delay: 32861ms;
}

@keyframes move-frames-84 {
  from {
    transform: translate3d(274px, 82vh, 0);
  }

  to {
    transform: translate3d(119px, -103vh, 0);
  }
}

.circle-container:nth-child(84) .circle {
  animation-delay: 2026ms;
}

.circle-container:nth-child(85) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-85;
  animation-duration: 31436ms;
  animation-delay: 9129ms;
}

@keyframes move-frames-85 {
  from {
    transform: translate3d(84px, 86vh, 0);
  }

  to {
    transform: translate3d(20px, -96vh, 0);
  }
}

.circle-container:nth-child(85) .circle {
  animation-delay: 1515ms;
}

.circle-container:nth-child(86) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-86;
  animation-duration: 35182ms;
  animation-delay: 7947ms;
}

@keyframes move-frames-86 {
  from {
    transform: translate3d(165px, 84vh, 0);
  }

  to {
    transform: translate3d(211px, -112vh, 0);
  }
}

.circle-container:nth-child(86) .circle {
  animation-delay: 2031ms;
}

.circle-container:nth-child(87) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-87;
  animation-duration: 31291ms;
  animation-delay: 31599ms;
}

@keyframes move-frames-87 {
  from {
    transform: translate3d(243px, 83vh, 0);
  }

  to {
    transform: translate3d(279px, -99vh, 0);
  }
}

.circle-container:nth-child(87) .circle {
  animation-delay: 3933ms;
}

.circle-container:nth-child(88) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-88;
  animation-duration: 29681ms;
  animation-delay: 22300ms;
}

@keyframes move-frames-88 {
  from {
    transform: translate3d(95px, 82vh, 0);
  }

  to {
    transform: translate3d(152px, -110vh, 0);
  }
}

.circle-container:nth-child(88) .circle {
  animation-delay: 2229ms;
}

.circle-container:nth-child(89) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-89;
  animation-duration: 29154ms;
  animation-delay: 2580ms;
}

@keyframes move-frames-89 {
  from {
    transform: translate3d(201px, 85vh, 0);
  }

  to {
    transform: translate3d(223px, -92vh, 0);
  }
}

.circle-container:nth-child(89) .circle {
  animation-delay: 2794ms;
}

.circle-container:nth-child(90) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-90;
  animation-duration: 34525ms;
  animation-delay: 26414ms;
}

@keyframes move-frames-90 {
  from {
    transform: translate3d(168px, 83vh, 0);
  }

  to {
    transform: translate3d(140px, -103vh, 0);
  }
}

.circle-container:nth-child(90) .circle {
  animation-delay: 2370ms;
}

.circle-container:nth-child(91) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-91;
  animation-duration: 35255ms;
  animation-delay: 23822ms;
}

@keyframes move-frames-91 {
  from {
    transform: translate3d(226px, 84vh, 0);
  }

  to {
    transform: translate3d(244px, -109vh, 0);
  }
}

.circle-container:nth-child(91) .circle {
  animation-delay: 405ms;
}

.circle-container:nth-child(92) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-92;
  animation-duration: 36161ms;
  animation-delay: 5724ms;
}

@keyframes move-frames-92 {
  from {
    transform: translate3d(261px, 84vh, 0);
  }

  to {
    transform: translate3d(179px, -98vh, 0);
  }
}

.circle-container:nth-child(92) .circle {
  animation-delay: 2036ms;
}

.circle-container:nth-child(93) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-93;
  animation-duration: 30959ms;
  animation-delay: 244ms;
}

@keyframes move-frames-93 {
  from {
    transform: translate3d(106px, 87vh, 0);
  }

  to {
    transform: translate3d(256px, -90vh, 0);
  }
}

.circle-container:nth-child(93) .circle {
  animation-delay: 2290ms;
}

.circle-container:nth-child(94) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-94;
  animation-duration: 35541ms;
  animation-delay: 13689ms;
}

@keyframes move-frames-94 {
  from {
    transform: translate3d(164px, 83vh, 0);
  }

  to {
    transform: translate3d(124px, -107vh, 0);
  }
}

.circle-container:nth-child(94) .circle {
  animation-delay: 2211ms;
}

.circle-container:nth-child(95) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-95;
  animation-duration: 30583ms;
  animation-delay: 34921ms;
}

@keyframes move-frames-95 {
  from {
    transform: translate3d(200px, 81vh, 0);
  }

  to {
    transform: translate3d(153px, -97vh, 0);
  }
}

.circle-container:nth-child(95) .circle {
  animation-delay: 1132ms;
}

.circle-container:nth-child(96) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-96;
  animation-duration: 32852ms;
  animation-delay: 24262ms;
}

@keyframes move-frames-96 {
  from {
    transform: translate3d(62px, 82vh, 0);
  }

  to {
    transform: translate3d(80px, -110vh, 0);
  }
}

.circle-container:nth-child(96) .circle {
  animation-delay: 2621ms;
}

.circle-container:nth-child(97) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-97;
  animation-duration: 35686ms;
  animation-delay: 35292ms;
}

@keyframes move-frames-97 {
  from {
    transform: translate3d(61px, 89vh, 0);
  }

  to {
    transform: translate3d(263px, -99vh, 0);
  }
}

.circle-container:nth-child(97) .circle {
  animation-delay: 3712ms;
}

.circle-container:nth-child(98) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-98;
  animation-duration: 32101ms;
  animation-delay: 36617ms;
}

@keyframes move-frames-98 {
  from {
    transform: translate3d(135px, 81vh, 0);
  }

  to {
    transform: translate3d(57px, -104vh, 0);
  }
}

.circle-container:nth-child(98) .circle {
  animation-delay: 849ms;
}

.circle-container:nth-child(99) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-99;
  animation-duration: 35886ms;
  animation-delay: 25177ms;
}

@keyframes move-frames-99 {
  from {
    transform: translate3d(184px, 81vh, 0);
  }

  to {
    transform: translate3d(44px, -98vh, 0);
  }
}

.circle-container:nth-child(99) .circle {
  animation-delay: 197ms;
}

.circle-container:nth-child(100) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-100;
  animation-duration: 31111ms;
  animation-delay: 2700ms;
}

@keyframes move-frames-100 {
  from {
    transform: translate3d(2px, 85vh, 0);
  }

  to {
    transform: translate3d(117px, -94vh, 0);
  }
}

.circle-container:nth-child(100) .circle {
  animation-delay: 2814ms;
}

.circle-container:nth-child(101) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-101;
  animation-duration: 33319ms;
  animation-delay: 7270ms;
}

@keyframes move-frames-101 {
  from {
    transform: translate3d(95px, 81vh, 0);
  }

  to {
    transform: translate3d(54px, -94vh, 0);
  }
}

.circle-container:nth-child(101) .circle {
  animation-delay: 1971ms;
}

.circle-container:nth-child(102) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-102;
  animation-duration: 34846ms;
  animation-delay: 7661ms;
}

@keyframes move-frames-102 {
  from {
    transform: translate3d(264px, 87vh, 0);
  }

  to {
    transform: translate3d(58px, -107vh, 0);
  }
}

.circle-container:nth-child(102) .circle {
  animation-delay: 2716ms;
}

.circle-container:nth-child(103) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-103;
  animation-duration: 36241ms;
  animation-delay: 33975ms;
}

@keyframes move-frames-103 {
  from {
    transform: translate3d(101px, 83vh, 0);
  }

  to {
    transform: translate3d(224px, -89vh, 0);
  }
}

.circle-container:nth-child(103) .circle {
  animation-delay: 2451ms;
}

.circle-container:nth-child(104) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-104;
  animation-duration: 36826ms;
  animation-delay: 32207ms;
}

@keyframes move-frames-104 {
  from {
    transform: translate3d(276px, 84vh, 0);
  }

  to {
    transform: translate3d(181px, -107vh, 0);
  }
}

.circle-container:nth-child(104) .circle {
  animation-delay: 2379ms;
}

.circle-container:nth-child(105) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-105;
  animation-duration: 34488ms;
  animation-delay: 34999ms;
}

@keyframes move-frames-105 {
  from {
    transform: translate3d(122px, 88vh, 0);
  }

  to {
    transform: translate3d(202px, -90vh, 0);
  }
}

.circle-container:nth-child(105) .circle {
  animation-delay: 1992ms;
}

.circle-container:nth-child(106) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-106;
  animation-duration: 32622ms;
  animation-delay: 20878ms;
}

@keyframes move-frames-106 {
  from {
    transform: translate3d(197px, 83vh, 0);
  }

  to {
    transform: translate3d(52px, -101vh, 0);
  }
}

.circle-container:nth-child(106) .circle {
  animation-delay: 652ms;
}

.circle-container:nth-child(107) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-107;
  animation-duration: 28807ms;
  animation-delay: 1271ms;
}

@keyframes move-frames-107 {
  from {
    transform: translate3d(116px, 90vh, 0);
  }

  to {
    transform: translate3d(242px, -97vh, 0);
  }
}

.circle-container:nth-child(107) .circle {
  animation-delay: 965ms;
}

.circle-container:nth-child(108) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-108;
  animation-duration: 33201ms;
  animation-delay: 14866ms;
}

@keyframes move-frames-108 {
  from {
    transform: translate3d(43px, 85vh, 0);
  }

  to {
    transform: translate3d(134px, -109vh, 0);
  }
}

.circle-container:nth-child(108) .circle {
  animation-delay: 2170ms;
}

.circle-container:nth-child(109) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-109;
  animation-duration: 35221ms;
  animation-delay: 27598ms;
}

@keyframes move-frames-109 {
  from {
    transform: translate3d(67px, 84vh, 0);
  }

  to {
    transform: translate3d(116px, -106vh, 0);
  }
}

.circle-container:nth-child(109) .circle {
  animation-delay: 2208ms;
}

.circle-container:nth-child(110) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-110;
  animation-duration: 30334ms;
  animation-delay: 33225ms;
}

@keyframes move-frames-110 {
  from {
    transform: translate3d(75px, 89vh, 0);
  }

  to {
    transform: translate3d(75px, -109vh, 0);
  }
}

.circle-container:nth-child(110) .circle {
  animation-delay: 267ms;
}

.circle-container:nth-child(111) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-111;
  animation-duration: 34046ms;
  animation-delay: 29178ms;
}

@keyframes move-frames-111 {
  from {
    transform: translate3d(115px, 85vh, 0);
  }

  to {
    transform: translate3d(211px, -105vh, 0);
  }
}

.circle-container:nth-child(111) .circle {
  animation-delay: 3715ms;
}

.circle-container:nth-child(112) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-112;
  animation-duration: 34001ms;
  animation-delay: 18743ms;
}

@keyframes move-frames-112 {
  from {
    transform: translate3d(270px, 90vh, 0);
  }

  to {
    transform: translate3d(226px, -101vh, 0);
  }
}

.circle-container:nth-child(112) .circle {
  animation-delay: 1638ms;
}

.circle-container:nth-child(113) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-113;
  animation-duration: 29383ms;
  animation-delay: 30055ms;
}

@keyframes move-frames-113 {
  from {
    transform: translate3d(13px, 81vh, 0);
  }

  to {
    transform: translate3d(42px, -92vh, 0);
  }
}

.circle-container:nth-child(113) .circle {
  animation-delay: 960ms;
}

.circle-container:nth-child(114) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-114;
  animation-duration: 31669ms;
  animation-delay: 4940ms;
}

@keyframes move-frames-114 {
  from {
    transform: translate3d(262px, 86vh, 0);
  }

  to {
    transform: translate3d(49px, -89vh, 0);
  }
}

.circle-container:nth-child(114) .circle {
  animation-delay: 3588ms;
}

.circle-container:nth-child(115) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-115;
  animation-duration: 34466ms;
  animation-delay: 5698ms;
}

@keyframes move-frames-115 {
  from {
    transform: translate3d(92px, 84vh, 0);
  }

  to {
    transform: translate3d(52px, -113vh, 0);
  }
}

.circle-container:nth-child(115) .circle {
  animation-delay: 3274ms;
}

.circle-container:nth-child(116) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-116;
  animation-duration: 36093ms;
  animation-delay: 22577ms;
}

@keyframes move-frames-116 {
  from {
    transform: translate3d(115px, 90vh, 0);
  }

  to {
    transform: translate3d(29px, -119vh, 0);
  }
}

.circle-container:nth-child(116) .circle {
  animation-delay: 1126ms;
}

.circle-container:nth-child(117) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-117;
  animation-duration: 29658ms;
  animation-delay: 6652ms;
}

@keyframes move-frames-117 {
  from {
    transform: translate3d(77px, 90vh, 0);
  }

  to {
    transform: translate3d(129px, -110vh, 0);
  }
}

.circle-container:nth-child(117) .circle {
  animation-delay: 1440ms;
}

.circle-container:nth-child(118) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-118;
  animation-duration: 32820ms;
  animation-delay: 497ms;
}

@keyframes move-frames-118 {
  from {
    transform: translate3d(131px, 84vh, 0);
  }

  to {
    transform: translate3d(11px, -88vh, 0);
  }
}

.circle-container:nth-child(118) .circle {
  animation-delay: 3963ms;
}

.circle-container:nth-child(119) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-119;
  animation-duration: 34138ms;
  animation-delay: 5712ms;
}

@keyframes move-frames-119 {
  from {
    transform: translate3d(250px, 82vh, 0);
  }

  to {
    transform: translate3d(57px, -96vh, 0);
  }
}

.circle-container:nth-child(119) .circle {
  animation-delay: 3601ms;
}

.circle-container:nth-child(120) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-120;
  animation-duration: 33656ms;
  animation-delay: 26330ms;
}

@keyframes move-frames-120 {
  from {
    transform: translate3d(193px, 87vh, 0);
  }

  to {
    transform: translate3d(237px, -90vh, 0);
  }
}

.circle-container:nth-child(120) .circle {
  animation-delay: 2029ms;
}

.circle-container:nth-child(121) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-121;
  animation-duration: 28114ms;
  animation-delay: 33571ms;
}

@keyframes move-frames-121 {
  from {
    transform: translate3d(84px, 83vh, 0);
  }

  to {
    transform: translate3d(166px, -89vh, 0);
  }
}

.circle-container:nth-child(121) .circle {
  animation-delay: 3346ms;
}

.circle-container:nth-child(122) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-122;
  animation-duration: 28909ms;
  animation-delay: 19529ms;
}

@keyframes move-frames-122 {
  from {
    transform: translate3d(172px, 87vh, 0);
  }

  to {
    transform: translate3d(134px, -116vh, 0);
  }
}

.circle-container:nth-child(122) .circle {
  animation-delay: 3387ms;
}

.circle-container:nth-child(123) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-123;
  animation-duration: 31725ms;
  animation-delay: 26433ms;
}

@keyframes move-frames-123 {
  from {
    transform: translate3d(45px, 90vh, 0);
  }

  to {
    transform: translate3d(48px, -95vh, 0);
  }
}

.circle-container:nth-child(123) .circle {
  animation-delay: 1408ms;
}

.circle-container:nth-child(124) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-124;
  animation-duration: 32943ms;
  animation-delay: 34344ms;
}

@keyframes move-frames-124 {
  from {
    transform: translate3d(86px, 87vh, 0);
  }

  to {
    transform: translate3d(219px, -103vh, 0);
  }
}

.circle-container:nth-child(124) .circle {
  animation-delay: 916ms;
}

.circle-container:nth-child(125) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-125;
  animation-duration: 34429ms;
  animation-delay: 34650ms;
}

@keyframes move-frames-125 {
  from {
    transform: translate3d(276px, 86vh, 0);
  }

  to {
    transform: translate3d(184px, -96vh, 0);
  }
}

.circle-container:nth-child(125) .circle {
  animation-delay: 465ms;
}

.circle-container:nth-child(126) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-126;
  animation-duration: 28313ms;
  animation-delay: 29263ms;
}

@keyframes move-frames-126 {
  from {
    transform: translate3d(57px, 87vh, 0);
  }

  to {
    transform: translate3d(153px, -111vh, 0);
  }
}

.circle-container:nth-child(126) .circle {
  animation-delay: 1609ms;
}

.circle-container:nth-child(127) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-127;
  animation-duration: 32043ms;
  animation-delay: 23584ms;
}

@keyframes move-frames-127 {
  from {
    transform: translate3d(33px, 89vh, 0);
  }

  to {
    transform: translate3d(143px, -108vh, 0);
  }
}

.circle-container:nth-child(127) .circle {
  animation-delay: 1292ms;
}

.circle-container:nth-child(128) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-128;
  animation-duration: 31377ms;
  animation-delay: 32962ms;
}

@keyframes move-frames-128 {
  from {
    transform: translate3d(123px, 82vh, 0);
  }

  to {
    transform: translate3d(155px, -90vh, 0);
  }
}

.circle-container:nth-child(128) .circle {
  animation-delay: 1662ms;
}

.circle-container:nth-child(129) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-129;
  animation-duration: 32696ms;
  animation-delay: 15930ms;
}

@keyframes move-frames-129 {
  from {
    transform: translate3d(9px, 84vh, 0);
  }

  to {
    transform: translate3d(1px, -108vh, 0);
  }
}

.circle-container:nth-child(129) .circle {
  animation-delay: 2590ms;
}

.circle-container:nth-child(130) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-130;
  animation-duration: 29903ms;
  animation-delay: 24310ms;
}

@keyframes move-frames-130 {
  from {
    transform: translate3d(62px, 87vh, 0);
  }

  to {
    transform: translate3d(29px, -116vh, 0);
  }
}

.circle-container:nth-child(130) .circle {
  animation-delay: 2062ms;
}

.circle-container:nth-child(131) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-131;
  animation-duration: 34925ms;
  animation-delay: 23910ms;
}

@keyframes move-frames-131 {
  from {
    transform: translate3d(264px, 84vh, 0);
  }

  to {
    transform: translate3d(172px, -112vh, 0);
  }
}

.circle-container:nth-child(131) .circle {
  animation-delay: 1204ms;
}

.circle-container:nth-child(132) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-132;
  animation-duration: 36171ms;
  animation-delay: 20405ms;
}

@keyframes move-frames-132 {
  from {
    transform: translate3d(94px, 85vh, 0);
  }

  to {
    transform: translate3d(41px, -86vh, 0);
  }
}

.circle-container:nth-child(132) .circle {
  animation-delay: 3331ms;
}

.circle-container:nth-child(133) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-133;
  animation-duration: 29105ms;
  animation-delay: 7596ms;
}

@keyframes move-frames-133 {
  from {
    transform: translate3d(89px, 83vh, 0);
  }

  to {
    transform: translate3d(50px, -107vh, 0);
  }
}

.circle-container:nth-child(133) .circle {
  animation-delay: 2306ms;
}

.circle-container:nth-child(134) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-134;
  animation-duration: 34543ms;
  animation-delay: 1371ms;
}

@keyframes move-frames-134 {
  from {
    transform: translate3d(258px, 86vh, 0);
  }

  to {
    transform: translate3d(170px, -108vh, 0);
  }
}

.circle-container:nth-child(134) .circle {
  animation-delay: 2509ms;
}

.circle-container:nth-child(135) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-135;
  animation-duration: 30839ms;
  animation-delay: 10162ms;
}

@keyframes move-frames-135 {
  from {
    transform: translate3d(74px, 88vh, 0);
  }

  to {
    transform: translate3d(18px, -100vh, 0);
  }
}

.circle-container:nth-child(135) .circle {
  animation-delay: 3501ms;
}

.circle-container:nth-child(136) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-136;
  animation-duration: 35680ms;
  animation-delay: 10499ms;
}

@keyframes move-frames-136 {
  from {
    transform: translate3d(17px, 84vh, 0);
  }

  to {
    transform: translate3d(104px, -92vh, 0);
  }
}

.circle-container:nth-child(136) .circle {
  animation-delay: 1957ms;
}

.circle-container:nth-child(137) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-137;
  animation-duration: 31842ms;
  animation-delay: 27043ms;
}

@keyframes move-frames-137 {
  from {
    transform: translate3d(122px, 87vh, 0);
  }

  to {
    transform: translate3d(39px, -117vh, 0);
  }
}

.circle-container:nth-child(137) .circle {
  animation-delay: 2124ms;
}

.circle-container:nth-child(138) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-138;
  animation-duration: 34497ms;
  animation-delay: 36070ms;
}

@keyframes move-frames-138 {
  from {
    transform: translate3d(11px, 84vh, 0);
  }

  to {
    transform: translate3d(194px, -92vh, 0);
  }
}

.circle-container:nth-child(138) .circle {
  animation-delay: 452ms;
}

.circle-container:nth-child(139) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-139;
  animation-duration: 30423ms;
  animation-delay: 16662ms;
}

@keyframes move-frames-139 {
  from {
    transform: translate3d(257px, 88vh, 0);
  }

  to {
    transform: translate3d(4px, -98vh, 0);
  }
}

.circle-container:nth-child(139) .circle {
  animation-delay: 3995ms;
}

.circle-container:nth-child(140) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-140;
  animation-duration: 29413ms;
  animation-delay: 32161ms;
}

@keyframes move-frames-140 {
  from {
    transform: translate3d(184px, 83vh, 0);
  }

  to {
    transform: translate3d(10px, -111vh, 0);
  }
}

.circle-container:nth-child(140) .circle {
  animation-delay: 875ms;
}

.circle-container:nth-child(141) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-141;
  animation-duration: 28990ms;
  animation-delay: 28866ms;
}

@keyframes move-frames-141 {
  from {
    transform: translate3d(111px, 82vh, 0);
  }

  to {
    transform: translate3d(115px, -93vh, 0);
  }
}

.circle-container:nth-child(141) .circle {
  animation-delay: 548ms;
}

.circle-container:nth-child(142) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-142;
  animation-duration: 30912ms;
  animation-delay: 10041ms;
}

@keyframes move-frames-142 {
  from {
    transform: translate3d(75px, 89vh, 0);
  }

  to {
    transform: translate3d(49px, -119vh, 0);
  }
}

.circle-container:nth-child(142) .circle {
  animation-delay: 1361ms;
}

.circle-container:nth-child(143) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-143;
  animation-duration: 35339ms;
  animation-delay: 15562ms;
}

@keyframes move-frames-143 {
  from {
    transform: translate3d(189px, 84vh, 0);
  }

  to {
    transform: translate3d(91px, -106vh, 0);
  }
}

.circle-container:nth-child(143) .circle {
  animation-delay: 2752ms;
}

.circle-container:nth-child(144) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-144;
  animation-duration: 35584ms;
  animation-delay: 9761ms;
}

@keyframes move-frames-144 {
  from {
    transform: translate3d(109px, 86vh, 0);
  }

  to {
    transform: translate3d(113px, -104vh, 0);
  }
}

.circle-container:nth-child(144) .circle {
  animation-delay: 990ms;
}

.circle-container:nth-child(145) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-145;
  animation-duration: 34603ms;
  animation-delay: 11969ms;
}

@keyframes move-frames-145 {
  from {
    transform: translate3d(147px, 81vh, 0);
  }

  to {
    transform: translate3d(273px, -91vh, 0);
  }
}

.circle-container:nth-child(145) .circle {
  animation-delay: 1875ms;
}

.circle-container:nth-child(146) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-146;
  animation-duration: 32540ms;
  animation-delay: 656ms;
}

@keyframes move-frames-146 {
  from {
    transform: translate3d(224px, 81vh, 0);
  }

  to {
    transform: translate3d(82px, -92vh, 0);
  }
}

.circle-container:nth-child(146) .circle {
  animation-delay: 1849ms;
}

.circle-container:nth-child(147) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-147;
  animation-duration: 30318ms;
  animation-delay: 32211ms;
}

@keyframes move-frames-147 {
  from {
    transform: translate3d(91px, 85vh, 0);
  }

  to {
    transform: translate3d(229px, -103vh, 0);
  }
}

.circle-container:nth-child(147) .circle {
  animation-delay: 73ms;
}

.circle-container:nth-child(148) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-148;
  animation-duration: 34387ms;
  animation-delay: 22297ms;
}

@keyframes move-frames-148 {
  from {
    transform: translate3d(38px, 87vh, 0);
  }

  to {
    transform: translate3d(220px, -108vh, 0);
  }
}

.circle-container:nth-child(148) .circle {
  animation-delay: 1731ms;
}

.circle-container:nth-child(149) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-149;
  animation-duration: 28972ms;
  animation-delay: 30148ms;
}

@keyframes move-frames-149 {
  from {
    transform: translate3d(23px, 88vh, 0);
  }

  to {
    transform: translate3d(223px, -96vh, 0);
  }
}

.circle-container:nth-child(149) .circle {
  animation-delay: 2481ms;
}

.circle-container:nth-child(150) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-150;
  animation-duration: 29219ms;
  animation-delay: 10302ms;
}

@keyframes move-frames-150 {
  from {
    transform: translate3d(260px, 88vh, 0);
  }

  to {
    transform: translate3d(162px, -101vh, 0);
  }
}

.circle-container:nth-child(150) .circle {
  animation-delay: 1620ms;
}

.circle-container:nth-child(151) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-151;
  animation-duration: 36372ms;
  animation-delay: 36288ms;
}

@keyframes move-frames-151 {
  from {
    transform: translate3d(178px, 89vh, 0);
  }

  to {
    transform: translate3d(30px, -111vh, 0);
  }
}

.circle-container:nth-child(151) .circle {
  animation-delay: 2322ms;
}

.circle-container:nth-child(152) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-152;
  animation-duration: 36751ms;
  animation-delay: 17559ms;
}

@keyframes move-frames-152 {
  from {
    transform: translate3d(49px, 82vh, 0);
  }

  to {
    transform: translate3d(99px, -102vh, 0);
  }
}

.circle-container:nth-child(152) .circle {
  animation-delay: 2501ms;
}

.circle-container:nth-child(153) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-153;
  animation-duration: 30785ms;
  animation-delay: 30342ms;
}

@keyframes move-frames-153 {
  from {
    transform: translate3d(223px, 81vh, 0);
  }

  to {
    transform: translate3d(275px, -86vh, 0);
  }
}

.circle-container:nth-child(153) .circle {
  animation-delay: 3514ms;
}

.circle-container:nth-child(154) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-154;
  animation-duration: 30191ms;
  animation-delay: 195ms;
}

@keyframes move-frames-154 {
  from {
    transform: translate3d(241px, 81vh, 0);
  }

  to {
    transform: translate3d(273px, -92vh, 0);
  }
}

.circle-container:nth-child(154) .circle {
  animation-delay: 3657ms;
}

.circle-container:nth-child(155) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-155;
  animation-duration: 28614ms;
  animation-delay: 27731ms;
}

@keyframes move-frames-155 {
  from {
    transform: translate3d(205px, 89vh, 0);
  }

  to {
    transform: translate3d(247px, -106vh, 0);
  }
}

.circle-container:nth-child(155) .circle {
  animation-delay: 3202ms;
}

.circle-container:nth-child(156) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-156;
  animation-duration: 34034ms;
  animation-delay: 33232ms;
}

@keyframes move-frames-156 {
  from {
    transform: translate3d(212px, 88vh, 0);
  }

  to {
    transform: translate3d(260px, -107vh, 0);
  }
}

.circle-container:nth-child(156) .circle {
  animation-delay: 1056ms;
}

.circle-container:nth-child(157) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-157;
  animation-duration: 29800ms;
  animation-delay: 5707ms;
}

@keyframes move-frames-157 {
  from {
    transform: translate3d(182px, 81vh, 0);
  }

  to {
    transform: translate3d(184px, -90vh, 0);
  }
}

.circle-container:nth-child(157) .circle {
  animation-delay: 3783ms;
}

.circle-container:nth-child(158) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-158;
  animation-duration: 31684ms;
  animation-delay: 33328ms;
}

@keyframes move-frames-158 {
  from {
    transform: translate3d(264px, 81vh, 0);
  }

  to {
    transform: translate3d(245px, -95vh, 0);
  }
}

.circle-container:nth-child(158) .circle {
  animation-delay: 1410ms;
}

.circle-container:nth-child(159) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-159;
  animation-duration: 29909ms;
  animation-delay: 34903ms;
}

@keyframes move-frames-159 {
  from {
    transform: translate3d(110px, 89vh, 0);
  }

  to {
    transform: translate3d(132px, -118vh, 0);
  }
}

.circle-container:nth-child(159) .circle {
  animation-delay: 2641ms;
}

.circle-container:nth-child(160) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-160;
  animation-duration: 28928ms;
  animation-delay: 33786ms;
}

@keyframes move-frames-160 {
  from {
    transform: translate3d(71px, 89vh, 0);
  }

  to {
    transform: translate3d(18px, -90vh, 0);
  }
}

.circle-container:nth-child(160) .circle {
  animation-delay: 3110ms;
}

.circle-container:nth-child(161) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-161;
  animation-duration: 34579ms;
  animation-delay: 36858ms;
}

@keyframes move-frames-161 {
  from {
    transform: translate3d(8px, 85vh, 0);
  }

  to {
    transform: translate3d(204px, -113vh, 0);
  }
}

.circle-container:nth-child(161) .circle {
  animation-delay: 3508ms;
}

.circle-container:nth-child(162) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-162;
  animation-duration: 34557ms;
  animation-delay: 32910ms;
}

@keyframes move-frames-162 {
  from {
    transform: translate3d(5px, 84vh, 0);
  }

  to {
    transform: translate3d(226px, -104vh, 0);
  }
}

.circle-container:nth-child(162) .circle {
  animation-delay: 468ms;
}

.circle-container:nth-child(163) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-163;
  animation-duration: 34895ms;
  animation-delay: 35856ms;
}

@keyframes move-frames-163 {
  from {
    transform: translate3d(39px, 89vh, 0);
  }

  to {
    transform: translate3d(23px, -96vh, 0);
  }
}

.circle-container:nth-child(163) .circle {
  animation-delay: 506ms;
}

.circle-container:nth-child(164) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-164;
  animation-duration: 33224ms;
  animation-delay: 12355ms;
}

@keyframes move-frames-164 {
  from {
    transform: translate3d(5px, 84vh, 0);
  }

  to {
    transform: translate3d(172px, -94vh, 0);
  }
}

.circle-container:nth-child(164) .circle {
  animation-delay: 2834ms;
}

.circle-container:nth-child(165) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-165;
  animation-duration: 34388ms;
  animation-delay: 18167ms;
}

@keyframes move-frames-165 {
  from {
    transform: translate3d(176px, 86vh, 0);
  }

  to {
    transform: translate3d(206px, -90vh, 0);
  }
}

.circle-container:nth-child(165) .circle {
  animation-delay: 433ms;
}

.circle-container:nth-child(166) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-166;
  animation-duration: 36930ms;
  animation-delay: 15871ms;
}

@keyframes move-frames-166 {
  from {
    transform: translate3d(110px, 85vh, 0);
  }

  to {
    transform: translate3d(42px, -93vh, 0);
  }
}

.circle-container:nth-child(166) .circle {
  animation-delay: 2823ms;
}

.circle-container:nth-child(167) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-167;
  animation-duration: 35505ms;
  animation-delay: 15884ms;
}

@keyframes move-frames-167 {
  from {
    transform: translate3d(279px, 86vh, 0);
  }

  to {
    transform: translate3d(66px, -98vh, 0);
  }
}

.circle-container:nth-child(167) .circle {
  animation-delay: 3670ms;
}

.circle-container:nth-child(168) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-168;
  animation-duration: 29382ms;
  animation-delay: 22595ms;
}

@keyframes move-frames-168 {
  from {
    transform: translate3d(44px, 90vh, 0);
  }

  to {
    transform: translate3d(136px, -97vh, 0);
  }
}

.circle-container:nth-child(168) .circle {
  animation-delay: 715ms;
}

.circle-container:nth-child(169) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-169;
  animation-duration: 31644ms;
  animation-delay: 16970ms;
}

@keyframes move-frames-169 {
  from {
    transform: translate3d(10px, 89vh, 0);
  }

  to {
    transform: translate3d(98px, -102vh, 0);
  }
}

.circle-container:nth-child(169) .circle {
  animation-delay: 1144ms;
}

.circle-container:nth-child(170) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-170;
  animation-duration: 29030ms;
  animation-delay: 21763ms;
}

@keyframes move-frames-170 {
  from {
    transform: translate3d(112px, 83vh, 0);
  }

  to {
    transform: translate3d(38px, -105vh, 0);
  }
}

.circle-container:nth-child(170) .circle {
  animation-delay: 1500ms;
}

.circle-container:nth-child(171) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-171;
  animation-duration: 31386ms;
  animation-delay: 754ms;
}

@keyframes move-frames-171 {
  from {
    transform: translate3d(66px, 88vh, 0);
  }

  to {
    transform: translate3d(91px, -91vh, 0);
  }
}

.circle-container:nth-child(171) .circle {
  animation-delay: 3024ms;
}

.circle-container:nth-child(172) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-172;
  animation-duration: 31109ms;
  animation-delay: 6923ms;
}

@keyframes move-frames-172 {
  from {
    transform: translate3d(2px, 86vh, 0);
  }

  to {
    transform: translate3d(122px, -87vh, 0);
  }
}

.circle-container:nth-child(172) .circle {
  animation-delay: 3463ms;
}

.circle-container:nth-child(173) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-173;
  animation-duration: 32773ms;
  animation-delay: 13098ms;
}

@keyframes move-frames-173 {
  from {
    transform: translate3d(250px, 89vh, 0);
  }

  to {
    transform: translate3d(86px, -92vh, 0);
  }
}

.circle-container:nth-child(173) .circle {
  animation-delay: 1949ms;
}

.circle-container:nth-child(174) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-174;
  animation-duration: 31571ms;
  animation-delay: 30144ms;
}

@keyframes move-frames-174 {
  from {
    transform: translate3d(229px, 89vh, 0);
  }

  to {
    transform: translate3d(264px, -109vh, 0);
  }
}

.circle-container:nth-child(174) .circle {
  animation-delay: 2616ms;
}

.circle-container:nth-child(175) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-175;
  animation-duration: 36098ms;
  animation-delay: 4626ms;
}

@keyframes move-frames-175 {
  from {
    transform: translate3d(178px, 85vh, 0);
  }

  to {
    transform: translate3d(99px, -96vh, 0);
  }
}

.circle-container:nth-child(175) .circle {
  animation-delay: 2478ms;
}

.circle-container:nth-child(176) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-176;
  animation-duration: 35549ms;
  animation-delay: 26403ms;
}

@keyframes move-frames-176 {
  from {
    transform: translate3d(136px, 83vh, 0);
  }

  to {
    transform: translate3d(102px, -98vh, 0);
  }
}

.circle-container:nth-child(176) .circle {
  animation-delay: 3655ms;
}

.circle-container:nth-child(177) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-177;
  animation-duration: 29522ms;
  animation-delay: 17263ms;
}

@keyframes move-frames-177 {
  from {
    transform: translate3d(229px, 82vh, 0);
  }

  to {
    transform: translate3d(151px, -106vh, 0);
  }
}

.circle-container:nth-child(177) .circle {
  animation-delay: 3072ms;
}

.circle-container:nth-child(178) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-178;
  animation-duration: 31786ms;
  animation-delay: 4789ms;
}

@keyframes move-frames-178 {
  from {
    transform: translate3d(53px, 89vh, 0);
  }

  to {
    transform: translate3d(234px, -93vh, 0);
  }
}

.circle-container:nth-child(178) .circle {
  animation-delay: 2276ms;
}

.circle-container:nth-child(179) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-179;
  animation-duration: 31414ms;
  animation-delay: 27694ms;
}

@keyframes move-frames-179 {
  from {
    transform: translate3d(205px, 86vh, 0);
  }

  to {
    transform: translate3d(201px, -99vh, 0);
  }
}

.circle-container:nth-child(179) .circle {
  animation-delay: 1012ms;
}

.circle-container:nth-child(180) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-180;
  animation-duration: 28953ms;
  animation-delay: 26555ms;
}

@keyframes move-frames-180 {
  from {
    transform: translate3d(87px, 81vh, 0);
  }

  to {
    transform: translate3d(43px, -94vh, 0);
  }
}

.circle-container:nth-child(180) .circle {
  animation-delay: 186ms;
}

.circle-container:nth-child(181) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-181;
  animation-duration: 30987ms;
  animation-delay: 8691ms;
}

@keyframes move-frames-181 {
  from {
    transform: translate3d(18px, 89vh, 0);
  }

  to {
    transform: translate3d(221px, -108vh, 0);
  }
}

.circle-container:nth-child(181) .circle {
  animation-delay: 3113ms;
}

.circle-container:nth-child(182) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-182;
  animation-duration: 37000ms;
  animation-delay: 16330ms;
}

@keyframes move-frames-182 {
  from {
    transform: translate3d(254px, 85vh, 0);
  }

  to {
    transform: translate3d(165px, -103vh, 0);
  }
}

.circle-container:nth-child(182) .circle {
  animation-delay: 589ms;
}

.circle-container:nth-child(183) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-183;
  animation-duration: 33806ms;
  animation-delay: 17828ms;
}

@keyframes move-frames-183 {
  from {
    transform: translate3d(126px, 81vh, 0);
  }

  to {
    transform: translate3d(179px, -104vh, 0);
  }
}

.circle-container:nth-child(183) .circle {
  animation-delay: 2371ms;
}

.circle-container:nth-child(184) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-184;
  animation-duration: 31743ms;
  animation-delay: 26739ms;
}

@keyframes move-frames-184 {
  from {
    transform: translate3d(249px, 81vh, 0);
  }

  to {
    transform: translate3d(200px, -94vh, 0);
  }
}

.circle-container:nth-child(184) .circle {
  animation-delay: 870ms;
}

.circle-container:nth-child(185) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-185;
  animation-duration: 35606ms;
  animation-delay: 11683ms;
}

@keyframes move-frames-185 {
  from {
    transform: translate3d(247px, 84vh, 0);
  }

  to {
    transform: translate3d(97px, -96vh, 0);
  }
}

.circle-container:nth-child(185) .circle {
  animation-delay: 3007ms;
}

.circle-container:nth-child(186) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-186;
  animation-duration: 33367ms;
  animation-delay: 708ms;
}

@keyframes move-frames-186 {
  from {
    transform: translate3d(267px, 82vh, 0);
  }

  to {
    transform: translate3d(121px, -102vh, 0);
  }
}

.circle-container:nth-child(186) .circle {
  animation-delay: 854ms;
}

.circle-container:nth-child(187) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-187;
  animation-duration: 34586ms;
  animation-delay: 29275ms;
}

@keyframes move-frames-187 {
  from {
    transform: translate3d(170px, 90vh, 0);
  }

  to {
    transform: translate3d(192px, -91vh, 0);
  }
}

.circle-container:nth-child(187) .circle {
  animation-delay: 3722ms;
}

.circle-container:nth-child(188) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-188;
  animation-duration: 33313ms;
  animation-delay: 33827ms;
}

@keyframes move-frames-188 {
  from {
    transform: translate3d(13px, 84vh, 0);
  }

  to {
    transform: translate3d(262px, -105vh, 0);
  }
}

.circle-container:nth-child(188) .circle {
  animation-delay: 1177ms;
}

.circle-container:nth-child(189) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-189;
  animation-duration: 31202ms;
  animation-delay: 10135ms;
}

@keyframes move-frames-189 {
  from {
    transform: translate3d(19px, 81vh, 0);
  }

  to {
    transform: translate3d(266px, -86vh, 0);
  }
}

.circle-container:nth-child(189) .circle {
  animation-delay: 2126ms;
}

.circle-container:nth-child(190) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-190;
  animation-duration: 35440ms;
  animation-delay: 28411ms;
}

@keyframes move-frames-190 {
  from {
    transform: translate3d(221px, 89vh, 0);
  }

  to {
    transform: translate3d(23px, -113vh, 0);
  }
}

.circle-container:nth-child(190) .circle {
  animation-delay: 1825ms;
}

.circle-container:nth-child(191) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-191;
  animation-duration: 29401ms;
  animation-delay: 13400ms;
}

@keyframes move-frames-191 {
  from {
    transform: translate3d(149px, 86vh, 0);
  }

  to {
    transform: translate3d(103px, -112vh, 0);
  }
}

.circle-container:nth-child(191) .circle {
  animation-delay: 1365ms;
}

.circle-container:nth-child(192) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-192;
  animation-duration: 31924ms;
  animation-delay: 6529ms;
}

@keyframes move-frames-192 {
  from {
    transform: translate3d(16px, 88vh, 0);
  }

  to {
    transform: translate3d(36px, -103vh, 0);
  }
}

.circle-container:nth-child(192) .circle {
  animation-delay: 1904ms;
}

.circle-container:nth-child(193) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-193;
  animation-duration: 29306ms;
  animation-delay: 11980ms;
}

@keyframes move-frames-193 {
  from {
    transform: translate3d(245px, 88vh, 0);
  }

  to {
    transform: translate3d(103px, -93vh, 0);
  }
}

.circle-container:nth-child(193) .circle {
  animation-delay: 1575ms;
}

.circle-container:nth-child(194) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-194;
  animation-duration: 36738ms;
  animation-delay: 1749ms;
}

@keyframes move-frames-194 {
  from {
    transform: translate3d(120px, 82vh, 0);
  }

  to {
    transform: translate3d(74px, -100vh, 0);
  }
}

.circle-container:nth-child(194) .circle {
  animation-delay: 3154ms;
}

.circle-container:nth-child(195) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-195;
  animation-duration: 31819ms;
  animation-delay: 21267ms;
}

@keyframes move-frames-195 {
  from {
    transform: translate3d(232px, 82vh, 0);
  }

  to {
    transform: translate3d(14px, -110vh, 0);
  }
}

.circle-container:nth-child(195) .circle {
  animation-delay: 1343ms;
}

.circle-container:nth-child(196) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-196;
  animation-duration: 34843ms;
  animation-delay: 19856ms;
}

@keyframes move-frames-196 {
  from {
    transform: translate3d(128px, 87vh, 0);
  }

  to {
    transform: translate3d(190px, -114vh, 0);
  }
}

.circle-container:nth-child(196) .circle {
  animation-delay: 201ms;
}

.circle-container:nth-child(197) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-197;
  animation-duration: 31877ms;
  animation-delay: 18675ms;
}

@keyframes move-frames-197 {
  from {
    transform: translate3d(183px, 84vh, 0);
  }

  to {
    transform: translate3d(200px, -85vh, 0);
  }
}

.circle-container:nth-child(197) .circle {
  animation-delay: 1882ms;
}

.circle-container:nth-child(198) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-198;
  animation-duration: 30527ms;
  animation-delay: 16487ms;
}

@keyframes move-frames-198 {
  from {
    transform: translate3d(235px, 81vh, 0);
  }

  to {
    transform: translate3d(26px, -98vh, 0);
  }
}

.circle-container:nth-child(198) .circle {
  animation-delay: 959ms;
}

.circle-container:nth-child(199) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-199;
  animation-duration: 36821ms;
  animation-delay: 2603ms;
}

@keyframes move-frames-199 {
  from {
    transform: translate3d(247px, 81vh, 0);
  }

  to {
    transform: translate3d(97px, -89vh, 0);
  }
}

.circle-container:nth-child(199) .circle {
  animation-delay: 1260ms;
}

.circle-container:nth-child(200) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-200;
  animation-duration: 29064ms;
  animation-delay: 31099ms;
}

@keyframes move-frames-200 {
  from {
    transform: translate3d(145px, 86vh, 0);
  }

  to {
    transform: translate3d(68px, -108vh, 0);
  }
}

.circle-container:nth-child(200) .circle {
  animation-delay: 3357ms;
}
