html {
  scroll-behavior: smooth;
}
.landing {
  color: #ffffff;
  font-family: "Play";

  background: linear-gradient(
      218.51deg,
      rgba(204, 51, 51, 0.4) -3.74%,
      rgba(14, 2, 75, 0.4) 15.63%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 91.82%, #000000 94.43%),
    linear-gradient(
      188.74deg,
      rgba(18, 0, 203, 0.1) 15.38%,
      rgba(255, 92, 0, 0.1) 100%
    ),
    linear-gradient(
      4.65deg,
      #140340 11.24%,
      rgba(36, 0, 203, 0) 37.88%,
      rgba(36, 0, 203, 0.404845) 37.88%,
      rgba(36, 0, 203, 0) 37.88%
    ),
    linear-gradient(
      135.97deg,
      rgba(0, 0, 43, 0.3) 15.9%,
      rgba(255, 46, 0, 0.3) 62.66%
    ),
    #0c0044;

  background-size: cover;
}

.on-modal-open {
  /* No scroll */
  position: fixed;
  width: 100%;

  z-index: -1;
}

.dark-mode {
  color: #000;
  background-image: url("../../../imgs/LightModeBackgroundMesh.svg");
}
