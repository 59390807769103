.landing-app-preview-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-app-preview-section-title {
  font-size: 40px;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
}

.landing-app-preview-section-image-container {
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  margin-bottom: 100px;
  max-width: 80%;
}

.landing-app-preview-section-topbar-and-main-container {
  background: #000;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  width: 100%;
  height: auto;
}

.landing-app-preview-section-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 850px) {
  .landing-app-preview-section-title {
    font-size: calc(12px + 4vw);
  }
}

#app-preview-full-img {
  max-width: 1025px;
  width: 80%;
  margin-top: 75px;
  margin-bottom: 100px;
}

#app-preview-chrome-tab {
  width: 100%;
  height: auto;
}

#app-preview-topbar {
  width: 100%;
  height: auto;
}

#preview-sidebar {
  width: calc(100% * 225 / 1025);
  height: auto;
}

#preview-swiping {
  width: calc(100% * 537.5 / 1025);
  height: auto;
}

#preview-messaging {
  width: calc(100% * 262.5 / 1025);
  height: auto;
}
