/* Fonts */
@font-face {
  font-family: Play;
  src: url(fonts/Play-Regular.ttf);
}

@font-face {
  font-family: Play;
  src: url(fonts/Play-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: FFMark;
  src: url(fonts/ffmark.otf);
}

@font-face {
  font-family: FFMark;
  src: url(fonts/ffmark_light.otf);
  font-weight: lighter;
}

@font-face {
  font-family: FFMark;
  src: url(fonts/ffmark_bold.otf);
  font-weight: bold;
}
