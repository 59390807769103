.join-form-container {
  margin-top: 30px;
}

.join-form-submitted {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 900;
  text-align: center;
}

.join-form-submitted img {
  width: 40%;
  margin-bottom: 40px;
}

.join-form-intro {
  font-size: 18px;
  margin-bottom: 40px;
}

.join-form-input-container {
  display: flex;
  flex-direction: column;
}

.join-form-section {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.join-form-section-text {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.join-form-section-input {
  width: 100%;
  background: transparent !important;
  border-radius: 5px;
  border-color: #fff;
  font-size: 18px;

  color: #fff !important;
}

.join-waitlist-submit-button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: -20px;
}
