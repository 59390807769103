.background1 {
  /* background: linear-gradient(
      237.1deg,
      rgba(255, 92, 0, 0.2) 0.66%,
      rgba(0, 0, 0, 0) 69.64%
    ),
    linear-gradient(
      107.56deg,
      rgba(36, 0, 203, 0.4) 0%,
      rgba(255, 46, 0, 0.16) 100%
    ),
    #0c0044; */
  padding-bottom: 100px;
}

/* Logos */
.sun-logo {
  padding-right: 50px;
}

.moon-logo {
  padding-right: 10px;
}

.slider-logo {
  margin-top: 2px;
  padding-right: 10px;
}

/* Top bar stuff */

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}

.topbar-left {
  display: flex;
  align-items: center;
}

.icon-lightning-bolt-button {
  margin-right: 15px;
}

.qupgg-logo {
  font-size: 42px;
  padding-left: 100px;
}

.topbar-right {
  display: flex;
  align-items: center;
}

.topbar-features,
.topbar-features:hover,
.topbar-features:focus,
.topbar-features:active {
  font-size: 24px;
  padding-right: 50px;
  text-decoration: none;

  color: #aaaaaa;
}

.topbar-about,
.topbar-about:hover,
.topbar-about:focus,
.topbar-about:active {
  font-size: 24px;
  padding-right: 50px;
  text-decoration: none;
  color: #aaaaaa;
}

.topbar-dark-mode,
.topbar-dark-mode:hover,
.topbar-dark-mode:focus,
.topbar-dark-mode:active {
  color: #343434;
}

/* Landing Page 1 Main Area */
.landing-page1-container {
  display: flex;
  flex-direction: row;
}

.landing-page1-left {
  margin-top: 200px;
  margin-left: 100px;
  max-width: 500px;
}

@media only screen and (min-width: 1143px) {
  .landing-page1-left {
    min-width: 500px;
  }
}

.landing-page1-right {
  margin-right: 50px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.profile-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.kirituna-card {
  margin-top: 140px;
}

.kennykalman-card {
  margin-top: 70px;
  margin-right: 30px;
}

@media only screen and (max-width: 1305px) {
  .kennykalman-card {
    display: none;
  }
}

.xirisiax-card {
  margin-top: 140px;
  margin-right: 30px;
}

.profile-card-png {
  max-width: 288px;
  width: 100%;
}

@media only screen and (max-width: 1600px) {
  .xirisiax-card {
    display: none;
  }
}

.down-arrow-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.down-arrow:hover {
  cursor: pointer;
  transform: scale(1.03);
}

/* Text stuff */

.queueup {
  font-size: 96px;
  line-height: 125px;

  -webkit-animation-delay: 5s;
  -moz-animation-delay: 5s;
  animation-delay: 5s;
}

.social-gaming-text-container {
  font-size: 24px;
  line-height: 28px;
  margin-top: 25px;
  max-width: 460px;
  height: 90px;
  overflow-y: hidden;
}

.subtext-phrases {
  animation: subtext_animate 25s infinite;
  animation-delay: 1.75s;
  animation-fill-mode: backwards;
  width: 100%;
  height: 90px;
  overflow-y: visible;
  backface-visibility: hidden;
  perspective: 1000;
}

.subtext-phrase {
  height: 90px;
  margin-bottom: 90px;
}

@keyframes subtext_animate {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  2.5%,
  22.5% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  25% {
    opacity: 0;
  }

  27.5%,
  47.5% {
    opacity: 1;
    transform: translate3d(0, -200%, 0);
  }

  50% {
    opacity: 0;
  }

  52.5%,
  72.5% {
    opacity: 1;
    transform: translate3d(0, -400%, 0);
  }

  75% {
    opacity: 0;
  }

  77.5%,
  97.5% {
    opacity: 1;
    transform: translate3d(0, -600%, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -700%, 0);
  }
}

.join-button {
  margin-top: 50px;
}

#first-bold {
  font-weight: bold;
}

/* Kirituna Fade In and Out */
/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-queueup {
  opacity: 0; /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.75s;
  -moz-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

.fade-in-social-gaming-text {
  opacity: 0; /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;

  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.fade-in-cards {
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fade-in-join-button {
  opacity: 0; /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;

  -webkit-animation-delay: 1.25s;
  -moz-animation-delay: 1.25s;
  animation-delay: 1.25s;
}

.fade-in-down-arrow {
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  opacity: 1; /* make things visible upon start */
  -webkit-animation: fadeOut ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeOut ease-in 1;
  animation: fadeOut ease-in 1;

  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
}

#topbar-right-button {
  display: none;
}

/* Mobile design */
@media only screen and (max-width: 850px) {
  .qupgg-logo {
    font-size: calc(18px + 2vw);
    padding-left: calc(50px + 2vw);
  }

  .icon-lightning-bolt-button {
    max-width: calc(50px + 1vw);
  }
  #topbar-right-button {
    display: flex;
    margin-right: 50px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 10px;
  }

  .topbar-right {
    all: none;
  }

  .hide {
    display: none !important;
  }

  #topbar-right-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    min-width: 80%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6);
    z-index: 1;
    right: 0;
    top: 98px;
    margin-right: 50px;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    padding-bottom: 50px;
    align-items: center;
    border-radius: 10px;
    border-top-right-radius: 0px;
  }

  .menu-button-on {
    background-color: rgba(0, 0, 0, 0.9);
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border: none !important;
  }

  .dropdown-light-dark-toggle {
    display: flex;
    align-items: center;
  }

  #topbar-right-dropdown > div,
  #topbar-right-dropdown > a {
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .sun-logo {
    padding-right: 0px;
  }

  .topbar-features,
  .topbar-features:hover,
  .topbar-features:focus,
  .topbar-features:active,
  .topbar-about,
  .topbar-about:hover,
  .topbar-about:focus,
  .topbar-about:active {
    padding-right: 0px;
  }

  .light-mode-menu {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }

  .transparent-mode-menu {
    background-color: transparent !important;
  }

  /* Main components */

  .landing-page1-container {
    flex-direction: column;
    align-items: center;
  }

  .landing-page1-left {
    margin-top: 75px;
    margin-left: 0px;
    text-align: center;
  }

  .queueup {
    font-size: calc(48px + 4vw);
  }

  .profile-cards > div {
    margin-top: 75px;
  }
}
