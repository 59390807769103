nav {
  background: rgba(33, 33, 33, 0.75);
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

nav img {
  height: 45px;
  margin-left: 30px;
}

h1 {
  font-size: 2.25em;
}

nav button {
  margin-right: 30px;
  height: 40px;
}
