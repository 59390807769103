.background4 {
  /* background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.75) 100%); */
}

.bottom-join-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 75px;
}

.ready-to-run-it-text {
  margin-top: 50px;
  font-size: 30px;
}

.bottom-join-button {
  margin-top: 25px;
}
