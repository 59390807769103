button.base-btn {
  color: #fff;
  background: #cc3333;
  border-radius: 5px;
  font-family: "Play";
  font-size: 18px;
}

button.base-btn:hover {
  color: #fff;
  transform: scale(1.01);
}

button p {
  margin-bottom: 0;
}
