.switch {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #aaaaaa;
  transition: 0.5s;
}

.slider:before {
  position: absolute;
  content: "";
  top: 2.5px;
  left: 3px;
  background-color: #333333;
  transition: 0.5s;
}

.switch input {
  display: none;
}

input:checked + .slider {
  background-color: #333333;
}

input:checked + .slider:before {
  transform: translateX(19px);
  background: #aaaaaa;
}

.slider.round {
  border-radius: 40px;
}

.slider.round:before {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
