.landing-features-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.landing-features-title {
  text-align: center;
  font-size: 40px;
  margin-top: 25px;
}

.landing-features-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

/* Swiping Feature Component */
.landing-features-swiping-text {
  font-size: 24px;
  margin-left: 50px;
  text-align: center;
  max-width: 425px;
}

.landing-features-swiping-image {
  background-color: #000;
  border-radius: 15px;
  margin-right: 50px;
  max-width: 70%;
  height: auto;
}

.features-swiping {
  width: 100%;
  height: auto;
}

.features-swiping-png {
  max-width: 537.5px;
  width: 100%;
}

.scroll-messages-png,
.features-feed-png {
  max-width: 332px;
  width: 100%;
}

.features-no-profile-pics-png {
  max-width: 235px;
  width: 100%;
}

@media only screen and (max-width: 1068px) {
  .landing-features-component.swiping {
    display: flex;
    flex-direction: column;
  }

  .landing-features-swiping-text {
    margin-top: 40px;
    margin-left: 0px;
  }

  .landing-features-swiping-image {
    margin-right: 0px;
  }
}

/* Lightning Bolt Button Feature Component */
.landing-features-lightning-bolt-button-image {
  margin-right: 50px;
}

.landing-features-lightning-bolt-button-text {
  margin-left: 50px;
  font-size: 24px;
  text-align: center;
  max-width: 400px;
}

@media only screen and (max-width: 1068px) {
  .landing-features-component.lightning-bolt-button {
    display: flex;
    flex-direction: column;
  }

  .landing-features-lightning-bolt-button-text {
    margin-top: 25px;
    margin-left: 0px;
  }

  .landing-features-lightning-bolt-button-image {
    margin-right: 0px;
  }
}

/* Scroll Message Feature Component */
.landing-features-scroll-messages-text {
  font-size: 24px;
  text-align: center;
  max-width: 433px;
  margin-right: 50px;
}

.landing-features-scroll-messages-image {
  margin-left: 50px;
}

.in-paragraph-lightning-bolt {
  margin-left: 3px;
  margin-right: 3px;
}

@media only screen and (max-width: 1068px) {
  .landing-features-component.scroll-messages {
    display: flex;
    flex-direction: column-reverse;
  }

  .landing-features-scroll-messages-text {
    margin-top: 25px;
    margin-right: 0px;
  }

  .landing-features-scroll-messages-image {
    margin-left: 0px;
  }
}

/* Feed Feature Component */

.landing-features-feed-text {
  margin-right: 50px;
  font-size: 24px;
  max-width: 369px;
  text-align: center;
}

.landing-features-feed-image {
  margin-left: 50px;
}

@media only screen and (max-width: 1068px) {
  .landing-features-component.feed {
    display: flex;
    flex-direction: column-reverse;
  }

  .landing-features-feed-text {
    margin-top: 40px;
    margin-right: 0px;
  }

  .landing-features-feed-image {
    margin-left: 0px;
  }
}

/* No Profile Pictures Feature Component */

.landing-features-no-profile-pictures-text {
  margin-left: 50px;
  font-size: 24px;
  max-width: 350px;
  text-align: center;
}

.landing-features-no-profile-pictures-image {
  margin-right: 50px;
}

@media only screen and (max-width: 1068px) {
  .landing-features-component.no-profile-pictures {
    display: flex;
    flex-direction: column;
  }

  .landing-features-no-profile-pictures-text {
    margin-top: 40px;
    margin-left: 0px;
  }

  .landing-features-no-profile-pictures-image {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 850px) {
  .landing-features-title {
    font-size: calc(12px + 4vw);
  }

  .landing-features-container {
    padding-bottom: 50px;
  }

  .landing-features-swiping-text,
  .landing-features-lightning-bolt-button-text,
  .landing-features-scroll-messages-text,
  .landing-features-feed-text,
  .landing-features-no-profile-pictures-text {
    font-size: calc(8px + 2vw);
    margin-left: 30px;
    margin-right: 30px;
  }
}
